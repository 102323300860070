@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import './Colors.less';

.ant-typography,
p,
span,
a {

  font-family: 'Poppins', sans-serif;
  color: @white;

  &.titleText {
    width: 100%;
    height: 100px;
    display: flex;
    line-height: 34.5px;
    letter-spacing: 2px;
    font-size: 25px;
    justify-content: center;
    align-items: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.underline {
    margin-top: 2px;
    border-radius: 2px;
    border-bottom: 4px solid @darkBitcoin;
  }

  &.darkBitcoin {
    color: @darkBitcoin;
  }

  &.normal {
    font-weight: 400;
  }

  &.bold {
    font-weight: 700;
  }

  &.white {
    color: @white;
  }

  &.black {
    color: @black;
  }

  &.error {
    font-weight: bold;
    color: @error;
  }

  &.center {
    text-align: center;
    width: 100%;
    margin: 0;
    display: inline-block;
  }

  &.h1 {
    font-size: 5rem;
    font-weight: bolder;
  }

  &.h2 {
    font-size: 3.5rem;
    font-weight: bold;
  }

  &.h3 {
    font-size: 1rem;
    font-weight: bold;
  }

  &.small {
    font-size: 0.6rem;
  }

  &.medium {
    font-size: 1.25rem;
  }

  &.big {
    font-size: 3rem;
  }
}

.ant-btn {
  &:hover {
    color: @bitcoin;
  }
}

@media only screen and (max-width: 768px) {
  .ant-typography {
    font-size: 0.8rem;

    &.small {
      font-size: 0.8rem;
    }

    &.medium {
      font-size: 1rem;
    }

    &.big {
      font-size: 1.5rem;
    }
  }
}