@import '../../../style/Colors.less';

.resourceCollage {
  width: 100%;

  min-height: 400px;

  background-color: @white;
  
  padding: 2rem 0;

  .resourceTitle {
    font-size: 25px;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 1rem 0;
  }

  .seeAllResources {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resourcesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .resources {
      display: flex;
      flex-wrap: wrap;

      width: 520px;

      justify-content: space-evenly;


      .resource {
        max-width: 160px;
        max-height: 160px;

        margin: 1rem 0;

        &:hover {
          cursor: pointer;
        }

        .ant-image {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            border-radius: 80px;
            border: 10px solid @white3;
            width: 140px;
            height: 140px;
          }
        }

        .resourceText {
          text-align: center;
          display: inline-block;
          width: 100%;
          font-size: 1rem;
          font-weight: bold;
          padding: 10px 0;
        }
      }
    }
  }
}



@media only screen and (max-width: 768px) {
  .resourceCollage {
    
  
    .resourceTitle {
      font-size: 16px;  
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;