@import './colors.less';

.ant-pagination {
  .ant-pagination-prev, .ant-pagination-next {

    .ant-pagination-item-link {
      background-color: @darkBitcoin;
      border-color: @darkBitcoin;
      color: @white;
      border-radius: 20px;
    }
  }

  .ant-pagination-item {
    background-color: @black;
    border-radius: 20px;

    &.ant-pagination-item-active {
      border-color: @darkBitcoin;
      border-width: 2px;
    }

    a {
      color: @white;
    }
  }
}