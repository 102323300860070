@import '../../style/Colors.less';

.main {
  .header {
    padding-left: 50px;
    height: 650px;
    display: flex;

    width: 100%;

    background-color: @black;

    vertical-align: middle;
    align-items: center;
    justify-content: center;

    .siteTitle {
      .title {
        font-size: 45px;
        line-height: 72px;
        letter-spacing: 8px;
        width: 100%;
        display: inline-block;
        text-align: left;
      }

      .subTitle {
        font-size: 30px;
        line-height: 37.5px;
      }
    }

    .getStartedButton {
      position: relative;
      bottom: -100px;
      right: 100px;
    }
  }

  .sherpaCollageWrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .quizWrapper {

    display: flex;
    align-items: center;
    flex-direction: column;

    max-height: 500px;

    .quizHeader {
      width: 100%;
      height: 100px;
      display: flex;
      line-height: 34.5px;
      letter-spacing: 2px;
      font-size: 25px;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 992px) {
  .main {

    .quizWrapper {
      

      .quizHeader {
        font-size: 14px;
        letter-spacing: 2px;
        display: inline-block;
        padding: 25px 0;
      }
    }

    .header {

      height: 473px;

      .siteTitle {

        padding: 40px;
        display: inline-block;

        .title {
          font-size: 25px;
          line-height: 32.5px;
          letter-spacing: 4px;
        }

        .subTitle {
          display: inline-block;
          text-align: left;
          line-height: 25px;
          font-size: 20px;
          padding-top: 20px;
        }

        .getStartedButton {
          display: none;
        }
      }
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;