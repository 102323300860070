@import '../../../style/Colors.less';

.sherpaCollageMobile {

  min-width: 720px;
  background-color: white;
  padding: 1rem;

  border-bottom: 1px solid @white4;

  flex-direction: row;

  display: flex;
  justify-content: center;
  align-items: center;


  .imageSkeletons {
    display: flex;
    flex-direction: row;
    width: 25%;
    flex-wrap: wrap;

    min-height: 200px;
  }

  .showAllSherpas {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collageWrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 720px;
    margin: 1rem 0;
  }
}

.sherpaModal {
  background-color: transparent;

  .ant-modal-content {
    background: @black;

    .sherpaCard {
      background: @black;
      color: @white;
      box-shadow: none;

      .contentContainer {

        .sherpaTitle,
        .website,
        .description {

          .ant-typography,
          a {
            color: @white;

            &:hover {
              color: @darkBitcoin;
            }
          }
        }
      }
    }

    .ant-modal-close {
      background-color: @darkBitcoin;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      top: 10px;
      right: 10px;

      .ant-modal-close-x {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}


@media only screen and (max-width: 992px) {

  .sherpaCollageMobile {

    max-width: 414px;
    min-width: 400px;

    padding: 0 10px;

    .showAllSherpas {

      .titleText {
        font-size: 16px;
      }
    }

    .collageWrapper {
      max-width: 414px;
      min-width: 400px;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;