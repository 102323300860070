@import './colors.less';

.ant-btn {

  border-radius: 25px;

  &.darkBitcoin {
    background-color: @darkBitcoin;
    border-color: @darkBitcoin;
    color: @white;

    a {
      color: @white;
      &:hover {
        color: @white;
      }
    }
  }
}