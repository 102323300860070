@import '../../style/colors.less';

@imageSize: 160px;
@imageSizeMobile: 90px;

.sherpaTile {

  min-width: @imageSize;
  max-width: @imageSize;

  border-radius: 40px;

  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  .ant-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      min-width: @imageSize;
      max-width: @imageSize;
      min-height: @imageSize;
      max-height: @imageSize;
      border-radius: 80px;
      border: 10px solid @white3;
    }
  }

  .sherpaName {
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    width: 100%;
    font-weight: bold;
    padding: 10px 0;
  }
}


@media only screen and (max-width: 992px) {

  .sherpaTile {

    min-width: @imageSizeMobile;
    min-height: @imageSizeMobile;

    border-radius: 40px;

    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }

    .ant-image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        min-width: @imageSizeMobile;
        max-width: @imageSizeMobile;
        min-height:@imageSizeMobile;
        max-height: @imageSizeMobile;
        border-radius: 80px;
        border: 10px solid @white3;
      }
    }

    .sherpaName {
      font-size: 1rem;
      text-align: center;
      display: inline-block;
      width: 100%;
      font-weight: bold;
      padding: 10px 0;
    }
  }

}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;