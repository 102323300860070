

.sherpaForm, .resourceForm {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-left: 25%;

  width: 50%;

  .formTitle {
    font-size: 2.5rem;
    margin: 2rem 0;
  }

  .label {
    border-top: 1px solid black;
    margin-top: 2rem;
  }

  .formItem {
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
  }


  .formItem, label {
    width: 100%;
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;