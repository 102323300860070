@import '../../style/Colors.less';

.quiz {
  padding: 2rem;
  flex-direction: column;
  z-index: 1;
  margin: 2rem 2rem 0 2rem;
  border-radius: 2rem;
  position: relative;

  width: 1085px;
  max-height: 344px;
  height: 344px;

  background-color: @white2;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15), -2px -2px 10px rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  .quizControls {
    .clearButton {
      position: absolute;
      right: 100px;
      bottom: 20px;
    }
  }

  .questionsContainer {

    display: inline-block;
    width: 777px;

    .questionContainer {
      display: inline;
      margin-bottom: 25px;

      &:last-of-type {
        padding-bottom: 2rem;
      }

      .multiSelectQuestion,
      .singleSelectQuestion {
        padding: 0;
        margin-right: .5rem;

        &.filled {
          .ant-select-selector {
            border: 0;
            font-weight: bolder;
            border-bottom: 0;
          }
        }

        .ant-select-selector {

          background-color: @white2;
          text-transform: uppercase;
          text-align: center;

          border: 0;
          border-bottom: 2px dashed @black;


          .ant-select-selection-search {
            right: 0;
            left: 0;
          }

          .ant-select-selection-placeholder {
            text-align: center;
            padding: 0;
            color: @black;
          }

          .ant-select-selection-item {
            color: @black;
            text-align: center;
            padding: 0;
            display: block;
          }
        }

        .ant-select-arrow {
          display: none;
        }
      }

      .answer {
        font-style: italic;
        font-weight: bold;
        color: @darkSlate;
      }

      .closeButton {
        color: @error;
        position: absolute;
        top: .5rem;
        right: 0rem;
        z-index: 1;
        font-size: 1rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.ant-select {
  min-width: 100px;
  max-width: 250px;

  .ant-select-clear {
    color: @error;
  }

  .ant-select-selector {

    .ant-select-selection-item {
      font-size: 1.25rem;
    }

    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background-color: transparent;
          border: none;
          font-size: 1.25rem;

          .ant-select-selection-item-content::after {
            content: ',';
          }

          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
    }
  }
}

.ant-select-item {
  width: 100%;

  .ant-select-item-option-content {
    white-space: unset;
    text-overflow: unset;
  }
}

.ant-select-dropdown {
  min-width: 150px !important;
  max-width: 200px !important;

  .ant-select-item {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {

  .quizWrapper {
    padding: 25px;

    .quiz {

      width: 300px;

      .questionsContainer {
        padding: 30px;
        width: 300px;

        

        .questionContainer {
          .ant-typography {
            font-size: 14px;
          }
        }

        .multiSelectQuestion,
        .singleSelectQuestion {

          font-size: 14px;
          .ant-select-selector {
            .ant-select-selection-item {
              font-size: 14px;

            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .quiz {
    max-width: 1000px;
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;