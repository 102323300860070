@import '../../style/Colors.less';

.sherpaCard {

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25), -2px -2px 10px rgba(0, 0, 0, 0.25);

  background: @white2;

  height: 312px;

  border-radius: 30px;

  width: 100%;
  padding: 25px;
  margin: 1rem 0;

  .imgContainer {

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 160px;

    max-width: 160px;

    img {
      border-radius: 80px;
    }
  }

  .contentContainer {

    padding: 25px 0px 0px 25px;

    .sherpaTitle {
      height: 2rem;
      font-weight: bold;
    }

    .website {
      height: 2rem;
      color: @black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: @black;
      }
    }

    .description {
      min-height: 80px;
      max-height: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }

    .social {
      height: 4rem;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;

      a {
        font-size: 2.5rem;
        padding: 0 1rem;
      }

      .twitter {
        color: @twitter;
      }

      .youtube {
        color: @youtube;
      }

      .instagram {
        color: @instagram;
      }

      .clubhouse {
        font-size: 2.5rem;
        padding: 0 1rem;
      }
    }
  }
}


@media only screen and (max-width: 992px) {
  .sherpaCard {
    .contentContainer {
      padding: 10px 0 0 10px;
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;