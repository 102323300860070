@import '../../style/Colors.less';

.allResources {

  min-height: 400px;
  display: flex;
  flex-direction: row;
  

  .resourcesHeader {
    background-color: @black;
    height: 321px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 0 1rem;
    border-right: 10px solid @white2;
    border-radius: 5px;

    .filterSelect {
      margin-top: 1rem;
      width: 210px;
    }

    .filterTitle {
      font-size: 1.5rem;
    }

    .filterCheckbox {
      width: 210px;
      margin: 0;
      margin-top: 15px;

      color: @black;

      span, .ant-checkbox {
        color: @black;
        margin-right: 5px;

        .ant-checkbox-inner {
          background-color: @black;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: @darkBitcoin;
            border-color: @darkBitcoin;
          }
        }
      }
    }
  }

  .resources {

    width: 100%;
    padding: 1rem 1rem 0 4rem;

    .resourcesTitle {
      font-size: 3rem;
    }

    .category {

      border-top: 10px solid @white2;
      border-radius: 5px;
      margin: 1rem 0;
      align-items: center;
      justify-content: start;
      
      .sherpaTile {
        margin: 0 .5rem;
      }

      .categoryTitle {
        width: 100%;
        text-transform: capitalize;
        font-size: 2.5rem;
        margin: 50px 0;
      }

      .resource {

        max-height: 12.5rem;

        a {
          display: flex;
          flex-direction: column;
          min-height: 12.5rem;
          max-height: 12.5rem;

          .imgWrapper {
            display: flex;
            justify-content: center;

            width: 100%;
            height: 10rem;
            max-height: 10rem;

            padding: .5rem;

            img {
              object-fit: contain;
              max-width: 100%;
            }
          }

          .resourceTitle {
            text-align: center;
            height: 25%;
            margin: 1rem 0;
          }
        }


      }
    }
  }
}

@media only screen and (max-width: 992px) {

  .allResources {
    .resourcesHeader {
      margin-bottom: 0;
    }

    .filters {
      border: none;
    }
  }

  .filters {
    background-color: @white4;
    padding: 2rem 0 !important;

    .ant-select {
      .ant-select-selector {
        background-color: @white4;
      }
    }

    .ant-checkbox-wrapper {
      width: 50%;
    }
  }

  .resources {
    .category {


      .categoryTitle {
        margin-top: 1rem;
        font-size: 1.5rem !important;
      }
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;