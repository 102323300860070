@import './colors.less';

.ant-select {

  .ant-select-arrow {
    right: 5px;
    color: @white;
    background-color: @darkBitcoin;
    border-radius: 10px;
    width: 16px;
    height: 16px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
  }

  .ant-select-selector {

    background-color: @white2;
    text-transform: uppercase;
    text-align: center;

    border: 0 !important;
    border-bottom: 2px dashed @black !important;


    .ant-select-selection-search {
      right: 0;
      left: 0;
    }

    .ant-select-selection-placeholder {
      text-align: center;
      padding: 0;
      color: @black;
    }

    .ant-select-selection-item {
      color: @black;
      text-align: center;
      padding: 0;
      display: block;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        padding: .5rem;
        padding: 2px;
      }
    }
  }
}