
@import './style/Colors.less';
@import './style/Text.less';
@import './style/Select.less';
@import './style/buttons.less';
@import './style/pagination.less';

#root {
  min-height: 100%;
}

.ant-layout {
  background-color: transparent;
}

.mobileShow {
  display: none;
}


@media only screen and (max-width: 992px) {

  
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;