@import '../../style/Colors.less';

.sherpas {
  margin: 0 !important;
  width: 100%;

  .sherpaPagination {
    margin: 2rem auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sherpaHeader {
    display: flex;

    margin-bottom: 2rem;

    background-color: @black;
    height: 321px;
    padding-left: 60px;

    .titleText {
      text-align: left;
      height: 100%;
      justify-content: flex-start;
    }
  }

  .sherpaSearch {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    .ant-input-affix-wrapper {

      border-radius: 1rem;
    }

  }

  .sherpasWrapper {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .sherpaWrapper {

      display: flex;

      min-width: 600px;
      width: 600px;
      max-width: 600px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .sherpas {
    .sherpaHeader {

      padding: 30px;

      .titleText {
        position: relative;
        top: 100px;
        display: inline-block;
      }
    }

    .sherpasWrapper {
      .sherpaWrapper {  
        min-width: 320px;
        width: 320px;
        max-width: 320px;
      }
    }
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;