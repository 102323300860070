.bitcoinWrapper {
  position: absolute;
  background-color: transparent;
  z-index: 1;

  .bitcoinImage {
    opacity: .2;
    transform: rotate(10deg);
    max-width: 200px;
  }

  &.position1 {
    bottom: -200px;
    left: -100px;
  }

  &.position2 {
    bottom: -80%;
    right: 25%;
  }

  &.position3 {
    left: 100px;
    bottom: -150%;
  }
  
}

@media only screen and (max-width: 992px) {
  .bitcoinImage {
    max-width: 100px !important;
  }
}
@primary-color: #1DA57A;@link-color: #FEFEFA;@layout-body-background: #FEFEFA;@layout-header-background: #000000;@processing-color: @primary-color;@error-color: #D8000C;